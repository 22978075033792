import { useEffect, useRef, useState } from "react";
import {
  Alert,
  ButtonGroup,
  Col,
  Container,
  Row,
  Table,
  ToggleButton,
} from "react-bootstrap";
import Select from "react-select";
import secrets from "../lib/server/secrets";
import { decryptRows, encryptRows, getKeys, Keys } from "../lib/encryption";
import UploadForm from "../components/UploadForm";
import ComputerImportForm from "../components/ComputerImportForm";
import S3ImportForm from "../components/S3ImportForm";
import { captureException } from "@sentry/nextjs";
import { GetServerSideProps } from "next";
import { Preview } from "../lib/preview";
import { FileUpload } from "../lib/file-upload";
import styled from "styled-components";

const TableCell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
`;
const TableHeader = styled.th`
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
`;

export interface IndexProps {
  k: string;
  iv: string;
}

export default function Index({ k, iv }: IndexProps) {
  const file = useRef<FileUpload | null>(null);
  const [processRows, setProcessRows] = useState(() => encryptRows);
  const originalPreview = useRef<Preview | null>(null);
  const [preview, setPreview] = useState<{ [key: string]: string }[] | null>(
    null
  );
  const [headers, setHeaders] = useState<readonly { value: string }[]>([]);
  const [state, setState] = useState("ready");
  const [message, setMessage] = useState<string | null>(null);
  const [keys, setKeys] = useState<Keys | null>(null);

  useEffect(() => {
    getKeys(k, iv).then(setKeys);
  }, [k, iv]);

  useEffect(() => {
    if (originalPreview.current && keys) {
      processRows(originalPreview.current.data, headers, keys).then(setPreview);
    }
  }, [processRows, headers, keys]);

  async function setFile(f: FileUpload) {
    setMessage(null);
    setState("loading");
    file.current = f;
    try {
      originalPreview.current = await f.preview();
      setState("ready");
    } catch (error) {
      setState("error");
      console.error(error);
      captureException(error);
    }
    setHeaders([]);
  }

  return (
    <Container className="py-4">
      <Alert
        variant="danger"
        show={state === "error"}
        dismissible
        onClose={() => setState("ready")}
      >
        An error occurred while encrypting your file.
      </Alert>
      <Alert
        variant="success"
        show={state === "success"}
        dismissible
        onClose={() => setState("ready")}
      >
        Success! Your file has been uploaded to S3.
      </Alert>
      <Alert
        variant="warning"
        show={!!message}
        dismissible
        onClose={() => setMessage(null)}
      >
        {message}
      </Alert>
      <Row className="mb-4">
        <Col>
          <S3ImportForm
            setFile={setFile}
            setMessage={setMessage}
            state={state}
          />
        </Col>
        <Col className="d-flex flex-column">
          <ComputerImportForm setFile={setFile} setMessage={setMessage} />
        </Col>
      </Row>
      {preview && originalPreview && (
        <>
          <h4>Select columns to mask</h4>
          <ButtonGroup className="mb-3">
            <ToggleButton
              type="radio"
              id="encrypt"
              variant="outline-primary"
              checked={processRows === encryptRows}
              onClick={() => setProcessRows(() => encryptRows)}
              value="encrypt"
            >
              Encrypt
            </ToggleButton>
            <ToggleButton
              type="radio"
              id="decrypt"
              variant="outline-primary"
              checked={processRows === decryptRows}
              onClick={() => setProcessRows(() => decryptRows)}
              value="decrypt"
            >
              Decrypt
            </ToggleButton>
          </ButtonGroup>
          <Select
            className="mb-3"
            isMulti
            value={headers}
            onChange={setHeaders}
            options={originalPreview.current?.meta?.fields?.map((value) => ({
              value,
            }))}
            getOptionLabel={(x) => x.value}
            isDisabled={state === "loading"}
          />
          <Table responsive>
            <thead>
              <tr>
                {originalPreview.current?.meta?.fields?.map((field, index) => (
                  <TableHeader key={index}>{field}</TableHeader>
                ))}
              </tr>
            </thead>
            <tbody>
              {preview.map((row, index) => (
                <tr key={index}>
                  {originalPreview.current?.meta?.fields?.map(
                    (field, index) => (
                      <TableCell key={index}>{row[field]}</TableCell>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </Table>

          {keys && (
            <UploadForm
              state={state}
              setState={setState}
              file={file.current}
              setMessage={setMessage}
              processRows={(rows) => processRows(rows, headers, keys)}
            />
          )}
        </>
      )}
    </Container>
  );
}

export const getServerSideProps: GetServerSideProps<IndexProps> = async () => {
  return {
    props: {
      k: (await secrets).ENC_KEY,
      iv: (await secrets).ENC_IV,
    },
  };
};
