import S3Form from "./S3Form";
import { client } from "../lib/client/client";
import bent from "bent";
import Papa from "papaparse";
import { FileUpload } from "../lib/file-upload";
import {
  Head,
  HeadRequest,
  PresignedGet,
  PresignGetRequest,
} from "../lib/api-types";

export interface S3ImportFormProps {
  setFile: (file: FileUpload) => void;
  setMessage: (message: string | null) => void;
  state: string;
}

export default function S3ImportForm({
  setFile,
  setMessage,
  state,
}: S3ImportFormProps) {
  async function handleS3Add(values: PresignGetRequest & HeadRequest) {
    const { url } = await client<PresignedGet>("json", "POST")(
      "/api/presign-get",
      values
    );
    setFile({
      config: { download: true },
      file: url,
      async size() {
        const { contentLength } = await client<Head>("json", "POST")(
          "/api/head",
          values
        );
        return contentLength;
      },
      async preview() {
        const response = await bent(
          "buffer",
          "GET",
          { Range: "bytes=0-131071" },
          200,
          206
        )(url);
        return await new Promise((resolve) => {
          // TODO: this will fail with very wide rows
          Papa.parse<{ [key: string]: string }>(
            new Blob([new Uint8Array(response)]),
            {
              complete: (data) => {
                if (data.errors[0]) {
                  setMessage(data.errors[0].message);
                }
                resolve({ data: data.data, meta: data.meta });
              },
              header: true,
              skipEmptyLines: true,
              preview: 128,
            }
          );
        });
      },
    });
  }

  return (
    <>
      <h4>Import from S3</h4>
      <S3Form
        state={state}
        onSubmit={handleS3Add}
        defaultValue="unmasked-pii-blend360"
      />
    </>
  );
}
