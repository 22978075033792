import S3Form from "./S3Form";
import { client } from "../lib/client/client";
import Papa, { ParseLocalConfig, ParseRemoteConfig } from "papaparse";
import bent, { BentResponse } from "bent";
import { ProgressBar } from "react-bootstrap";
import { useState } from "react";
import { captureException } from "@sentry/nextjs";
import { FileUpload } from "../lib/file-upload";
import {
  StartedUpload,
  StartUploadRequest,
  StopUploadRequest,
} from "../lib/api-types";
import { CompletedPart } from "@aws-sdk/client-s3";

export interface UploadFormProps {
  state: string;
  setState: (state: string) => void;
  file: FileUpload | null;
  setMessage: (message: string | null) => void;
  processRows: (
    rows: { [key: string]: string }[]
  ) => Promise<{ [key: string]: string }[]>;
}

export default function UploadForm({
  state,
  setState,
  file,
  setMessage,
  processRows,
}: UploadFormProps) {
  const [progress, setProgress] = useState<number | null>(null);

  async function handleSubmit(values: { bucket: string; key: string }) {
    if (file) {
      setState("loading");
      try {
        const numChunks = Math.ceil((await file.size()) / (1024 * 1024 * 10));
        const body: StartUploadRequest = { numChunks, ...values };
        const { urls, uploadId } = await client<StartedUpload>("json", "POST")(
          "/api/start-upload",
          body
        );
        let index = 0;
        const parts: Promise<CompletedPart>[] = [];
        setProgress(0);
        Papa.parse<{ [key: string]: string }>(file.file, {
          ...(file.config as ParseLocalConfig<{ [key: string]: string }, Blob> &
            ParseRemoteConfig<{ [key: string]: string }>),
          async chunk(data, parser) {
            parser.pause();
            if (data.errors[0]) {
              setMessage(data.errors[0].message);
            }
            const i = index++;
            setProgress((progress) => (progress ?? 0) + 1 / numChunks);
            parts.push(
              processRows(data.data)
                .then((hashed) => {
                  const csv =
                    Papa.unparse(hashed, {
                      delimiter: data.meta.delimiter,
                      newline: data.meta.linebreak,
                      columns: data.meta.fields,
                      header: i === 0,
                    }) + data.meta.linebreak;
                  return bent<BentResponse>("PUT")(urls[i], csv);
                })
                .then((response) => {
                  parser.resume();
                  return {
                    ETag: JSON.parse(response.headers.get("etag")),
                    PartNumber: i + 1,
                  };
                })
            );
          },
          async complete() {
            try {
              const awaitedParts = await Promise.all(parts);
              try {
                const body: StopUploadRequest = {
                  uploadId,
                  parts: awaitedParts,
                  ...values,
                };
                await client("POST", 204)("/api/stop-upload", body);
                setState("success");
              } catch (error) {
                setState("error");
                console.error(error);
              }
            } catch (error) {
              setState("error");
              console.error(error);
              captureException(error);
            }
            setProgress(null);
          },
          chunkSize: 1024 * 1024 * 10,
          header: true,
          skipEmptyLines: true,
        });
      } catch (error) {
        setState("error");
      }
    }
  }

  return (
    <>
      <h4>Upload to S3</h4>
      <S3Form
        onSubmit={handleSubmit}
        state={state}
        defaultValue="masked-pii-blend360i"
        newObject
      />
      {progress !== null && (
        <ProgressBar className="mb-3" now={progress * 100} />
      )}
    </>
  );
}
