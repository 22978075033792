import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { FileUpload } from "../lib/file-upload";
import { Preview } from "../lib/preview";

export interface ComputerImportForm {
  setFile: (file: FileUpload) => void;
  setMessage: (message: string | null) => void;
}

export default function ComputerImportForm({
  setFile,
  setMessage,
}: ComputerImportForm) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept: "text/csv",
    onDropAccepted: ([f]) => {
      setFile({
        config: {},
        file: f,
        async size() {
          return f.size;
        },
        preview() {
          return new Promise((resolve) => {
            const preview: Preview = {
              data: [],
              meta: null,
            };
            Papa.parse<{ [key: string]: string }>(f, {
              chunk(data) {
                if (data.errors[0]) {
                  setMessage(data.errors[0].message);
                }
                preview.data = [...preview.data, ...data.data];
                if (!preview.meta) {
                  preview.meta = data.meta;
                }
              },
              complete() {
                resolve(preview);
              },
              header: true,
              chunkSize: 1024 * 32,
              skipEmptyLines: true,
              preview: 128,
            });
          });
        },
      });
    },
  });

  return (
    <>
      <h4>Import from Computer</h4>
      <div
        {...getRootProps({
          className: `flex-grow-1 dropzone${
            isDragActive ? " dropzone-accept" : ""
          }${isDragAccept ? " dropzone-accept" : ""}${
            isDragReject ? " dropzone-reject" : ""
          }`,
        })}
      >
        <input {...getInputProps()} />
        <p className="my-auto">
          Drag and drop CSV files here, or click to select files
        </p>
      </div>
    </>
  );
}
