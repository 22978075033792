export interface Keys {
  cryptoKey: CryptoKey;
  cryptoIv: Uint8Array;
}

export async function encryptRows(
  rows: { [key: string]: string }[],
  headers: readonly { value: string }[],
  { cryptoKey, cryptoIv }: Keys
) {
  const te = new TextEncoder();
  return await Promise.all(
    rows.map(async (row) => {
      const copy = { ...row };
      for (const header of headers) {
        copy[header.value] = toStringBase64(
          await crypto.subtle.encrypt(
            { name: "AES-GCM", iv: cryptoIv },
            cryptoKey,
            te.encode(copy[header.value])
          )
        );
      }
      return copy;
    })
  );
}

export async function decryptRows(
  rows: { [key: string]: string }[],
  headers: readonly { value: string }[],
  { cryptoKey, cryptoIv }: Keys
) {
  const td = new TextDecoder();
  return await Promise.all(
    rows.map(async (row) => {
      const copy = { ...row };
      for (const header of headers) {
        try {
          copy[header.value] = td.decode(
            await crypto.subtle.decrypt(
              { name: "AES-GCM", iv: cryptoIv },
              cryptoKey,
              typedArrayFromBase64(copy[header.value])
            )
          );
        } catch (error) {
          copy[header.value] = "<couldn't decrypt>";
        }
      }
      ``;
      return copy;
    })
  );
}

export async function getKeys(k: string, iv: string) {
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    typedArrayFromBase64(k),
    { name: "AES-GCM" },
    true,
    ["encrypt", "decrypt"]
  );
  const cryptoIv = typedArrayFromBase64(iv);
  return { cryptoKey, cryptoIv };
}

function typedArrayFromBase64(base64: string) {
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
}

function toStringBase64(typedArray: ArrayBuffer) {
  return btoa(String.fromCharCode(...new Uint8Array(typedArray)));
}
